<script setup lang="ts">
const { open } = useAppModals();
const { data: luckySpinData } = useGetLuckySpinData();
const { type } = useRankedSystem();
const { isDesktop } = useDevice();
</script>

<template>
	<div
		v-if="luckySpinData?.wheelAvailable && isDesktop"
		class="fortune-wheel-aside"
		@click="open('LazyOModalWheelFortuneWheel')"
	>
		<MVipClubSpinnerWheel :type="type" :active="luckySpinData?.wheelAvailable" />
	</div>
</template>

<style lang="scss" scoped>
.fortune-wheel-aside {
	position: fixed;
	right: -50px;
	bottom: 320px;
	z-index: 1000;
}
</style>
